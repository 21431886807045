/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:393a8619-b112-460f-83da-0f8759ae1024',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_4JRzOE4Ap',
  aws_user_pools_web_client_id: '3bte85um1jf1uamn9upvrfs4p8',
  oauth: {},
  aws_user_files_s3_bucket: 'flexomask-usr231856-dev',
//   aws_user_files_s3_bucket: 'flexomask-in',
  aws_user_files_s3_bucket_region: 'us-east-1',
}


export default awsmobile;
