<template>
  <div id="app">
    <div class="logo">
      <img src="@/assets/FMlogo.svg" alt="flexomask logo" />
    </div>

    <amplify-authenticator username-alias="email">
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
        username-alias="email"
      >
      </amplify-sign-in>
    </amplify-authenticator>
    <!-- <Nav :msg="fname" /> -->

    <div v-if="signedIn">
      <Nav />
      <!-- <p>Email: {{ this.email }}</p> -->
      <p>Email: {{ this.$store.state.userinfo.uemail }}</p>
    </div>
    <div class="push"></div>
    <footer class="footer" v-if="!signedIn">
      <div class="browsers">
        <div><h2>Best with:</h2></div>
        <div>
          <img src="@/assets/chrome.png" alt="chrome logo" />
          Google Chrome
        </div>
        <div>
          <img src="@/assets/firefox.png" alt="firefox logo" />
          Mozilla Firefox
        </div>
        <div>
          <img src="@/assets/safari.png" alt="safari logo" />
          Apple Safari
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import { Auth, Hub } from "aws-amplify";

export default {
  data() {
    return {
      signedIn: false,
      email: "",
    };
  },
  beforeCreate() {
    Hub.listen("auth", (data) => {
      console.log("data:", data);
      const { payload } = data;
      if (payload.event === "signIn") {
        this.signedIn = true;
        this.$router.push("/about");
      }
      if (payload.event === "signOut") {
        this.$router.push("/");
        this.signedIn = false;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => (this.signedIn = false));
  },
  updated() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.email = user.attributes.email;
        this.$store.state.userinfo.uemail = this.email;
      })
      .catch((err) => console.log(err));
  },
  components: {
    Nav,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 65rem;
  margin: auto;
}
:root {
  --amplify-primary-color: #366798;
  --amplify-primary-tint: #366798;
  --amplify-primary-shade: #366798;
}
.logo {
  text-align: left;
  width: 5rem;
  height: 6rem;
  float: left;
  margin-left: 1.5rem;
}
.browsers {
  display: grid;
  /* image-rendering: inline; */
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  /* width: 60rem; */
}
.push {
  height: 5rem;
}
.footer {
  background-color: #eee;
  position: absolute;
  bottom: 0;
  height: 5rem;
  width: 100%;
  padding: 1rem;
  text-align: center;
  /* display: grid; */
  /* grid-template-columns: repeat(5 1fr); */
  /* grid-gap: 1rem; */
}
</style>
