import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index.js";
// import "./assets/reset.css";
import Amplify, { Auth } from "aws-amplify";
import "@aws-amplify/ui-vue";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  Auth,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
