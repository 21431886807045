import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authenticated: false,
    config: {
      uploads3: "flexomask-in",
      // downloads3: 'flexomask-out',
      downloads3: "flexomask-usr231856-dev/public/",
      // jstickets3: "",
      cloudbackend: false,
      checkexists: "https://flexomask.net/checkexists.php",
      submitjob: "https://flexomask.net/main.php",
      presignapi:
        "https://al5xp48bdi.execute-api.us-east-1.amazonaws.com/test/helloworld/",
    },
    userinfo: {
      co: "Anderson & Vreeland",
      site: "Arlington",
      uemail: "demo",
    },
    curfile: {
      inputPdfFile: "",
      fname: "",
      internamFn: "",
      fext: "",
      fsize: "",
      time: "",
      sepnames: [],
      swatchgroup: [],
      preview: "",
      phsize: "",
      pvsize: "",
      hsize: "",
      vsize: "",
      uom: "",
      mediabox: "",
      artbox: "",
      platecount: "",
      pdfsepdefs: [],
      maskwidth: "",
      chks3uri: "",
      chks3uriBucket: "",
      chks3uriKey: "",
      jobticket: {},
      jtchks3uri: "",
      jts3uri: "",
      jts3uriKey: "",
      jtpsUri: "",
      ups3uri: "",
      ups3uriBucket: "",
      ups3uriKey: "",
      uppsUri: "",
      uppsUriBucket: "",
      uppsUriKey: "",
      dlpsUri: "",
      dlpsUriBucket: "",
      dlpsUriKey: "",
      content: "",
      progress: "",
      status: "",
      filehandle: "",
    },
    prevup: {
      files: [],
    },
  },
  mutations: {
    updateUserinfo(state, data) {
      state.userinfo = data;
    },
    updatecurfile(state, data) {
      state.curfile = data;
    },
    updateUploaded(state, data) {
      state.prevup.files.unshift(data);
    },
  },
  actions: {
    ActUpdateUserinfo({ data }) {
      this.$store.commit("updateUserinfo", data);
    },
    ActUpdateUploaded({ data }) {
      this.$store.commit("updateUploaded", data);
    },
  },
});
