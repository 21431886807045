<template>
  <div>
    <div id="nav">
      <router-link to="/about">About</router-link> |
      <router-link to="/upload">Upload</router-link> |
      <router-link to="/masks">Masks</router-link> |
      <router-link to="/prefs">Prefs</router-link> |
      <router-link to="/logout">Logout</router-link>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "Nav",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
#nav {
  padding: 2rem;
  margin: 0rem 0rem 4rem 0rem;
  background-color: #fcfcfc;
  border-bottom: 1px solid;
  text-align: right;
}
#nav a {
  font-weight: bold;
  color: #999;
  text-decoration: none;
  padding: 0rem 2rem;
  display: inline;
}

#nav a.router-link-exact-active {
  color: #333;
}
.logo {
  text-align: left;
  width: 5rem;
  float: left;
  margin-left: 1.5rem;
}
.welcome {
  font-weight: bold;
  color: #999;
  text-align: left;
  float: left;
  padding: 2rem 0rem 0rem 1.5rem;
}
</style>
